import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../sass/ProductCard.scss";

export default class ProductCard extends Component {
  render() {
    return (
      <Link
        key={this.props.id}
        to={`/${this.props.url}/${this.props.id}`}
        className="product-card aspectRatioSizer">
        <svg viewBox="0 0 1 1" />
        <div>
          <div className="p-container">
            <p>{this.props.name}</p>
            <p>${this.props.price}.00</p>
          </div>
          <img src={this.props.image[0]} />
        </div>
      </Link>
    );
  }
}
