import React, { Component } from "react";
import { connect } from "react-redux";
import "../sass/ProductPage.scss";

class ProductPage extends Component {
  render() {
    const { tshirts, wigs, prints, loading, error } = this.props;
    // console.log(tshirts, wigs, prints);
    const products = { tshirts, wigs, prints };
    // console.log([this.props.match.params.url]);

    if (error) {
      return (
        <div className="error">
          <p> Oops! {error.message}</p>
          <p>Go back the way you came!</p>
        </div>
      );
    }

    if (loading) {
      return <div className="loading">loading...</div>;
    }

    // get user index from url
    const product =
      products[this.props.match.params.url][this.props.match.params.id - 1];

    // console.log(product);
    const images = product ? product.image : [];

    return (
      <div>
        {product ? (
          <div className="product-page">
            <div className="product-images">
              <img src={product.image[0]} />
              {images.length ? (
                images.map((image, index) =>
                  index === 0 ? "" : <img src={image} />
                )
              ) : (
                <div />
              )}
            </div>
            <div className="product-info">
              <h1>{product.name}</h1>
              <p>{product.description}</p>
              <p>${product.price}.00</p>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tshirts: state.tshirts,
  prints: state.prints,
  wigs: state.wigs,
  loading: state.loading,
  error: state.error
});

export default connect(mapStateToProps)(ProductPage);
