import {
  FETCH_ALL_TSHIRTS_SUCCESS,
  FETCH_ALL_WIGS_SUCCESS,
  FETCH_ALL_PRINTS_SUCCESS,
  LOADING_TRUE,
  ERROR_RECEIVED
} from "../actions/actions";

const initialState = {
  tshirts: [],
  wigs: [],
  prints: [],
  loading: false,
  error: null
};

export default function reducer(state = initialState, action) {
  // console.log("ACTION", action.payload);
  switch (action.type) {
    case FETCH_ALL_TSHIRTS_SUCCESS:
      return {
        ...state,
        loading: false,
        tshirts: action.payload.tshirts
      };
    case FETCH_ALL_WIGS_SUCCESS:
      return {
        ...state,
        loading: false,
        wigs: action.payload.wigs
      };
    case FETCH_ALL_PRINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        prints: action.payload.prints
      };

    case LOADING_TRUE:
      return {
        ...state,
        loading: true,
        error: null
      };

    case ERROR_RECEIVED:
      return {
        ...state,
        error: action.payload.error
      };

    default:
      return state;
  }
}
