import firebase from "firebase";
var config = {
  // MADDELYNN-HATTER FIRESTORE
  apiKey: "AIzaSyBsO4RF_qn3wIYC9TRtJcGombQiEVPPCgg",
  authDomain: "maddelynn-hatter.firebaseapp.com",
  databaseURL: "https://maddelynn-hatter.firebaseio.com",
  projectId: "maddelynn-hatter",
  storageBucket: "",
  messagingSenderId: "693880943543"
};
var fire = firebase.initializeApp(config);

const db = fire.firestore();

export { db };
