import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../sass/Home.scss";

import ProductCard from "./ProductCard";

// import ProductCarousel from "./ProductCarousel";
class Home extends Component {
  render() {
    const { tshirts, wigs, prints, loading, error } = this.props;
    console.log(tshirts, wigs, prints);

    if (error) {
      return (
        <div className="error">
          <p> Oops! {error.message}!</p>
          <p>Go back the way you came!</p>
        </div>
      );
    }

    if (loading) {
      return <div className="loading">loading</div>;
    }

    return (
      <div className="home">
        <div className="tshirts">
          <Link className="see-all" to="/tshirts">
            See all T-shirts
          </Link>
          <div className="product-list no-scroll">
            {tshirts &&
              tshirts.map((tshirt) => (
                <ProductCard key={tshirt.id} url={"tshirts"} {...tshirt} />
              ))}
          </div>
        </div>
        <div className="wigs">
          <Link className="see-all" to="/wigs">
            See all Wigs
          </Link>
          <div className="product-list no-scroll">
            {wigs &&
              wigs.map((wig) => (
                <ProductCard key={wig.id} url={"wigs"} {...wig} />
              ))}
          </div>
        </div>
        <div className="prints">
          <Link className="see-all" to="/prints">
            See all Prints
          </Link>
          <div className="product-list scroll">
            {prints &&
              prints.map((print) => (
                <ProductCard key={print.id} url={"prints"} {...print} />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tshirts: state.tshirts,
  prints: state.prints,
  wigs: state.wigs,
  loading: state.loading,
  error: state.error
});

export default connect(mapStateToProps)(Home);
