import { db } from "./../fire";

// ACTION TYPES
export const LOADING_TRUE = "LOADING_TRUE";
export const FETCH_ALL_TSHIRTS_SUCCESS = "FETCH_ALL_TSHIRTS_SUCCESS";
export const FETCH_ALL_WIGS_SUCCESS = "FETCH_ALL_WIGS_SUCCESS";
export const FETCH_ALL_PRINTS_SUCCESS = "FETCH_ALL_PRINTS_SUCCESS";
export const ERROR_RECEIVED = "ERROR_RECEIVED";

// ACTION CREATORS
export const loadingTrue = () => ({
  type: LOADING_TRUE
});

export const fetchAllTshirtsSuccess = (tshirts) => ({
  type: FETCH_ALL_TSHIRTS_SUCCESS,
  payload: { tshirts }
});

export const fetchAllWigsSuccess = (wigs) => ({
  type: FETCH_ALL_WIGS_SUCCESS,
  payload: { wigs }
});

export const fetchAllPrintsSuccess = (prints) => ({
  type: FETCH_ALL_PRINTS_SUCCESS,
  payload: { prints }
});

export const errorReceived = (error) => ({
  type: ERROR_RECEIVED,
  payload: { error }
});

// THUNKS
// export function fetchAllUsers() {
//   return async (dispatch) => {
//     // play loading animation
//     dispatch(loadingTrue());
//     try {
//       const response = await fetch(`https://bh-interview.now.sh/users`);
//       // catch errors not caught by fetch
//       handleErrors(response);

//       const users = await response.json();
//       // add users to state
//       dispatch(fetchAllUsersSuccess(users.data));
//       return users.data;
//     } catch (error) {
//       // show error
//       dispatch(errorReceived(error));
//     }
//   };
// }

export function fetchAllTshirts() {
  return (dispatch) => {
    // play loading animation
    dispatch(loadingTrue());
    return (
      db
        .collection("tshirts")
        .get()
        .then((querySnapshot) => {
          const tshirts = querySnapshot.docs.map((doc) => doc.data());
          // console.log("TSHIRTS", tshirts);
          // add tshirts to state
          dispatch(fetchAllTshirtsSuccess(tshirts));
          // console.log("TSHIRTS", tshirts);
          return tshirts;
        })
        // show error
        .catch((error) => dispatch(errorReceived(error)))
    );
  };
}

export function fetchAllWigs() {
  return (dispatch) => {
    // play loading animation
    dispatch(loadingTrue());

    return (
      db
        .collection("wigs")
        .get()
        .then((querySnapshot) => {
          const wigs = querySnapshot.docs.map((doc) => doc.data());
          // add wigs to state
          dispatch(fetchAllWigsSuccess(wigs));
          // console.log("WIGS", wigs);
          return wigs;
        })
        // show error
        .catch((error) => dispatch(errorReceived(error)))
    );
  };
}

export function fetchAllPrints() {
  return (dispatch) => {
    // play loading animation
    dispatch(loadingTrue());

    return (
      db
        .collection("prints")
        .get()
        .then((querySnapshot) => {
          const prints = querySnapshot.docs.map((doc) => doc.data());
          // add prints to state
          dispatch(fetchAllPrintsSuccess(prints));
          // console.log("PRINTS", prints);
          return prints;
        })
        // show error
        .catch((error) => dispatch(errorReceived(error)))
    );
  };
}

// export function addSticky(sticky, id) {
//   return async (dispatch) => {
//     // play loading animation
//     dispatch(loadingTrue());
//     try {
//       const response = await fetch(
//         `https://bh-interview.now.sh/users/${id}/posts`,
//         {
//           method: "post",
//           headers: {
//             // "Content-Type": "application/x-www-form-urlencoded"
//             "Content-type": "application/json"
//           },
//           body: JSON.stringify(sticky)
//         }
//       );
//       handleErrors(response);

//       const user = await response.json();
//       // update state to include new post
//       dispatch(fetchAllUsers());
//       return user.data;
//     } catch (error) {
//       dispatch(errorReceived(error));
//     }
//   };
// }

// HELPER FUNCTION
// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  if (!response.ok) {
    throw Error(response.status);
  }
  return response;
}
