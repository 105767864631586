import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Banner from "./components/Banner";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import ProductsList from "./components/ProductsList";
import ProductPage from "./components/ProductPage";
// import Sticky404 from "./components/Sticky404";
import Footer from "./components/Footer";

// import { fetchAllProducts } from "./actions/actions";

import {
  fetchAllTshirts,
  fetchAllWigs,
  fetchAllPrints
} from "./actions/actions";
import "./sass/App.scss";
// import { db } from "./fire";

class App extends Component {
  // constructor() {
  //   super();
  //   this.state = {
  //     products: []
  //   };
  // }
  // componentDidMount() {
  //   db.collection("products")
  //     .get()
  //     .then((querySnapshot) => {
  //       const data = querySnapshot.docs.map((doc) => doc.data());
  //       console.log(data);
  //       this.setState({ products: data });
  //     });
  // }
  // render() {
  //   const { products } = this.state;
  //   return (
  //     <div className="row">
  //       {products.map((product) => (
  //         <div key={product.uid} className="">
  //           <h5>{product.name}</h5>
  //           <h6>{product.price}</h6>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // }

  componentDidMount() {
    this.props.dispatch(fetchAllTshirts());
    this.props.dispatch(fetchAllWigs());
    this.props.dispatch(fetchAllPrints());
  }

  render() {
    return (
      <div className="App">
        <Router>
          <div className="wrapper">
            <Banner />
            <Navbar />
            <div className="content">
              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/:url" component={ProductsList} />
                  <Route exact path="/:url/:id" component={ProductPage} />
                  {/* <Route path="*" component={Sticky404} /> */}
                </Switch>
              </ScrollToTop>
            </div>
            <Footer />
          </div>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tshirts: state.tshirts,
  prints: state.prints,
  wigs: state.wigs
  // loading: state.loading,
  // error: state.error
});

export default connect(mapStateToProps)(App);

// const mapStateToProps = (state) => ({
//   products: state.items,
//   loading: state.loading,
//   error: state.message
// });

// export default connect(mapStateToProps)(App);
