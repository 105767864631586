import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../sass/ProductsList.scss";

import ProductCard from "./ProductCard";

// import ProductCarousel from "./ProductCarousel";
class ProductsList extends Component {
  render() {
    const { tshirts, wigs, prints, loading, error } = this.props;
    console.log(tshirts, wigs, prints);
    const productType = this.props.match.params.url;
    const allProducts = { tshirts, wigs, prints };
    const products = allProducts[productType];

    if (error) {
      return (
        <div className="error">
          <p> Oops! {error.message}!</p>
          <p>Go back the way you came!</p>
        </div>
      );
    }

    if (loading) {
      return <div className="loading">loading</div>;
    }

    return (
      <div className="products-list-page">
        <Link className="see-all" to={`/${this.props.match.params.url}`}>
          See all {productType.slice(0, 1).toUpperCase() + productType.slice(1)}
        </Link>
        <div className="products-list">
          {products &&
            products.map((product) => (
              <ProductCard
                key={product.id}
                url={this.props.match.params.url}
                {...product}
              />
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tshirts: state.tshirts,
  prints: state.prints,
  wigs: state.wigs,
  loading: state.loading,
  error: state.error
});

export default connect(mapStateToProps)(ProductsList);
