import React, { Component } from "react";
import "../sass/Footer.scss";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <p>
          &copy; Maddelynn Hatter 2019{" "}
          <a
            href="https://www.instagram.com/theonlymadd/"
            target="_blank"
            rel="noopener noreferrer">
            Instagram
          </a>
          .
        </p>
      </div>
    );
  }
}
