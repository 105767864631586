import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "../sass/Navbar.scss";

export default class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        {/* <Link to="/" className="site-name">
          <h1>MaDdElYNn HaTtEr</h1>
        </Link> */}
        <NavLink exact to="/" activeClassName="active" className="navbar-home">
          Home
          {/* needs to be span instead of img because of firefox bug */}
          {/* <span className="home-icon" />   */}
        </NavLink>
        <NavLink
          exact
          to="/tshirts"
          activeClassName="active"
          className="navbar-tshirts">
          T-shirts
          {/* needs to be span instead of img because of firefox bug */}
          {/* <span className="home-icon" />   */}
        </NavLink>
        <NavLink
          exact
          to="/wigs"
          activeClassName="active"
          className="navbar-wigs">
          Wigs
          {/* needs to be span instead of img because of firefox bug */}
          {/* <span className="home-icon" />   */}
        </NavLink>
        <NavLink
          exact
          to="/prints"
          activeClassName="active"
          className="navbar-prints">
          Prints
          {/* needs to be span instead of img because of firefox bug */}
          {/* <span className="home-icon" />   */}
        </NavLink>
      </div>
    );
  }
}
